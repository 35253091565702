import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { Row, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Table from "../../atoms/Table/Table";
import { Link } from "react-router-dom";
import { getAppendedSubOrdersInfo } from "../../../store/slices/freight/freightSlice";

const headerAttributes = [
  {
    id: "id",
    label: "Order Id",
  },
  {
    id: "totalWeight",
    label: "Total Weight",
  },
  {
    id: "itemsCount",
    label: "Line Items",
  },
];

export const BolDetailDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { billOfLading } = useSelector((state) => state.freight);
  const {
    bolDetail,
    appendedSubOrders: { loading, data: appendedSubOrdersData },
  } = useSelector((state) => state.freight);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    if (bolDetail.subOrderIds) {
      dispatch(
        getAppendedSubOrdersInfo({ subOrderIds: bolDetail.subOrderIds }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bolDetail.subOrderIds]);

  useEffect(() => {
    setDataList(
      appendedSubOrdersData.map((item) => ({
        id: item.id,
        view_suborder: (
          <Link to={`/sub-order/${item.id}`} target="_blank">
            {item.id}
          </Link>
        ),
        totalWeight: item.totalWeight,
        itemsCount: item.itemsCount,
      })),
    );
  }, [appendedSubOrdersData]);

  return (
    <Modal show={open} onHide={onClose}>
      <Modal.Header closeButton className="p-5">
        <Modal.Title>
          <h3 className="h5">BOL Details</h3>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="m-4">
        <Row>
          <div className="bol-detail-popup__table">
            <Typography variant="body1">
              SubOrders appended to BOL{" "}
              <Link to={`/sub-order/${billOfLading}`} target="_blank">
                {billOfLading}
              </Link>
            </Typography>
            <Table
              hideId
              headerAttributes={headerAttributes}
              dataList={dataList}
              isLoading={loading}
            />
          </div>
        </Row>
      </Modal.Body>

      <div className="d-flex justify-content-end align-items-center mx-4 mb-4 gap-4">
        <Button onClick={onClose}>Close</Button>
      </div>
    </Modal>
  );
};
