import { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./index.scss"; // This imports Bootstrap CSS
import "./App.scss";
import "./theming/theme.scss";
import Header from "./components/sections/Header/Header";
import Login from "./views/Login/Login";
import MyAccount from "./views/MyAccount/MyAccount";
import SubOrder from "./views/SubOrder/SubOrder";
import Orders from "./views/Orders/Orders";
import Quotes from "./views/Quotes/Quotes";
import Customers from "./views/Customers/Customers";
import Customer from "./views/Customer/Customer";
import Bol from "./views/Bol/Bol";
import NotFound from "./views/NotFound";
import { LoginContext } from "./contexts/LoginContext";
import Container from "react-bootstrap/Container";
import { AlertMessage } from "./components/atoms/AlertMessage/AlertMessage";
import ApiMessage from "./components/sections/ApiMessage/ApiMessage";
import AutoSaveWrapper from "./wrapper/AutoSaveWrapper";

function App() {
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [username, setUsername] = useState("");

  return (
    <div className="App">
      <AlertMessage>
        <LoginContext.Provider
          value={{ isAuthenticated, setAuthenticated, username, setUsername }}
        >
          {isAuthenticated ? (
            <>
              <Router>
                <Header />
                <ApiMessage />
                <Container className="">
                  <Routes>
                    <Route path="/" element={<SubOrder />} />
                    <Route
                      path="/customer/:customerId/new-order/"
                      element={<SubOrder />}
                    />
                    <Route
                      path="/quote/:id"
                      element={<SubOrder savedAsQuote />}
                    />
                    <Route path="/sub-order/new" element={<SubOrder />} />
                    <Route
                      path="/sub-order/:id"
                      element={
                        <AutoSaveWrapper>
                          <SubOrder savedAsOrder />
                        </AutoSaveWrapper>
                      }
                    />
                    <Route path="/order-search/" element={<Orders />} />
                    <Route path="/customers" element={<Customers />} />
                    <Route path="/customers/:id" element={<Customer />} />
                    <Route path="/bol" element={<Bol />} />
                    <Route path="/my-account" element={<MyAccount />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/quotes-search/" element={<Quotes />} />
                  </Routes>
                </Container>
              </Router>
            </>
          ) : (
            <Container fluid={"md"}>
              <Login />
            </Container>
          )}
        </LoginContext.Provider>
      </AlertMessage>
      <div style={{float: "right", marginRight: "1%"}}><b>v2.2.1</b></div>
    </div>
  );
}

export default App;