import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Autocomplete, Tooltip, IconButton } from "@mui/material";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { FaChevronRight } from "react-icons/fa";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import SOCustomerInfo from "../CustomerInfo/SOCustomerInfo";
import { Link, useParams } from "react-router-dom";
import { formStyle } from "../../../theming/formStyle";
import useFocusEvent from "../../../hooks/useFocusEvent";
import { setFieldRef } from "../../../store/slices/refsSlice";
import {
  setCustomerId,
  setCustomerName,
  searchCustomersById,
  searchCustomersByFuzzy,
  getCustomerById,
  setCustomerData,
  resetCustomer,
  getAllCustomers,
  searchCustomersByAdvanced,
} from "../../../store/slices/customer/customerSlice";

import {
  CUSTOMER_SECTION_REF,
  CUSTOMER_ID_REF,
  SUB_ORDER_PO,
} from "../../../helpers/const";
import AdvancedSearchPopup from "./AdvancedSearchPopup";
import "./CustomerWidget.scss";
import { setProductPriceTriggerChanged } from "../../../store/slices/eventHandlerSlice";

const CustomerWidget = () => {
  // Refs
  const customerIdRef = useRef();
  const customerNameRef = useRef();

  // Custom hooks
  const { onClickHandler } = useFocusEvent(
    CUSTOMER_SECTION_REF,
    CUSTOMER_ID_REF,
    customerIdRef,
  );

  //Router hooks
  const { id: subOrderId, customerId: customerIdUrlParam } = useParams();

  // Redux
  const dispatch = useDispatch();

  //const { subOrderId } = useSelector(state => state.subOrder)
  const { quoteId } = useSelector((state) => state.quote);
  const {
    customer: { idHasError, nameHasError },
  } = useSelector((state) => state.validations);
  const {
    customerId,
    customerName,
    customersListForId,
    customersListForFuzzy,
    total,
  } = useSelector((state) => state.customer);

  const [advancedSearchPopupOpen, setAdvancedSearchPopupOpen] = useState(false);

  useEffect(() => {
    dispatch(getAllCustomers());
  }, [dispatch]);

  /**
   * Reset the customer data whent the subOrder form is in creation mode
   */
  useEffect(() => {
    if (!subOrderId) {
      dispatch(resetCustomer());
    }
  }, [subOrderId, dispatch]);

  useEffect(() => {
    const cId = parseInt(customerIdUrlParam);
    if (!subOrderId && cId) {
      dispatch(getCustomerById(cId));
    }
  }, [customerIdUrlParam, subOrderId]);

  // Shortcut key handler, CTRL + ALT + f will show Advanced Search Popup
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.altKey && event.key === "f") {
        onClickAdvancedSearch();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const searchOptionsById = (id) => {
    dispatch(searchCustomersById(id));
  };

  const searchOptionsByFuzzy = (name) => {
    dispatch(setCustomerName(name));
    dispatch(searchCustomersByFuzzy(name));
  };

  const onClickAdvancedSearch = () => {
    setAdvancedSearchPopupOpen(true);
  };

  /**
   * Get the details of a customer
   * @param {number} id ID of the user
   */
  const getCustomerData = ({ id, customers }) => {
    const selectedCustomer = customers.find(
      (c) => c.id === id && c?.contactPhone,
    );
    if (selectedCustomer) {
      dispatch(setCustomerId(selectedCustomer.id));
      dispatch(setCustomerName(selectedCustomer.name));
      dispatch(setCustomerData(selectedCustomer));
    } else {
      dispatch(getCustomerById(id));
    }

    dispatch(setProductPriceTriggerChanged());
  };

  return (
    <Col className="CustomerWidget">
      <Row>
        <Col>
          <Card.Title>Customer</Card.Title>
        </Col>
        <Col sm={6} className="pt-1" style={{whiteSpace: "nowrap"}}>
          {customerId > 0 && (
            <Link className="" to={`/customers/${customerId}`}>
              See Client Details <FaChevronRight className="mx-4" />
            </Link>
          )}
        </Col>
      </Row>
      <Row className="mb-4">
        <Col sm={6} className="text-end cus-box" >
          <Autocomplete
            className="autocomplete-input"
            label="Customer Num"
            options={customersListForId}
            variant="filled"
            disabled={subOrderId || quoteId ? true : false}
            value={customerId ? customerId : null}
            getOptionLabel={(option) => {
              if (!option) {
                return option;
              } else if (typeof option === "number") {
                return option.toString();
              } else {
                return option.id.toString();
              }
            }}
            isOptionEqualToValue={(option, value) => option.id === value}
            onChange={(_event, value) => {
              if (value) {
                getCustomerData({
                  id: value.id,
                  customers: customersListForId,
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                label={
                  <span>
                    Customer Num <span className="text-danger">*</span>
                  </span>
                }
                {...params}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
                error={idHasError}
                helperText={<span className="text-danger">*REQUIRED</span>}
                value={customerId?.toString()}
                inputRef={customerIdRef}
                onChange={(e) => {
                  searchOptionsById(e.target.value);
                  if (!e.target.value) {
                    dispatch(setCustomerId(""));
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    customerNameRef.current.focus();
                  }
                }}
                onClick={onClickHandler}
              />
            )}
          />
        </Col>
        <Col sm={6} className="text-end cus-box">
          <div
            style={{
              display: "flex",
              alignItems: "start",
              width: "100%",
            }}
          >
            <Autocomplete
              className="autocomplete-input"
              label="Customer Name"
              disabled={subOrderId || quoteId ? true : false}
              options={[...customersListForFuzzy].sort((a, b) =>
                a.name > b.name ? 1 : -1,
              )}
              filterOptions={(options) => options}
              variant="filled"
              value={customerName}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                } else {
                  const city = option?.mainCity ? `${option?.mainCity}, ` : "";
                  return `${option.name} | ${city}${option?.mainState} ID ${option.id}`;
                }
              }}
              onChange={(_event, value) => {
                if (value) {
                  getCustomerData({
                    id: value.id,
                    customers: customersListForFuzzy,
                  });
                  dispatch(setFieldRef(SUB_ORDER_PO));
                }
              }}
              isOptionEqualToValue={(option, value) => option.name === value}
              renderInput={(params) => (
                <TextField
                  label={
                    <span>
                      Customer Name <span className="text-danger">*</span>
                    </span>
                  }
                  {...params}
                  style={formStyle.inputStyle}
                  InputLabelProps={formStyle.inputLabelProps}
                  error={nameHasError}
                  helperText={<span className="text-danger">*REQUIRED</span>}
                  value={customerName}
                  inputRef={customerNameRef}
                  onChange={(e) => {
                    searchOptionsByFuzzy(e.target.value);
                    if (!e.target.value) {
                      dispatch(setCustomerName(""));
                    }
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      dispatch(setFieldRef(SUB_ORDER_PO));
                    }
                  }}
                  onClick={onClickHandler}
                />
              )}
              sx={{ width: "100%" }}
            />
            {!subOrderId && (
              <Tooltip title="Advanced Search" arrow placement="top">
                <IconButton
                  color="primary"
                  aria-label="advanced-search"
                  onClick={onClickAdvancedSearch}
                  sx={{ marginTop: "4px" }}
                >
                  <ManageSearchIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </Col>
      </Row>
      <AdvancedSearchPopup
        open={advancedSearchPopupOpen}
        customersList={customersListForFuzzy}
        fuzzyQuery={customerId ? "" : customerName}
        total={total}
        setOpen={setAdvancedSearchPopupOpen}
        searchCustomersByAdvanced={(payload) => {
          dispatch(searchCustomersByAdvanced(payload));
        }}
        getCustomerData={getCustomerData}
      />
      <SOCustomerInfo />
    </Col>
  );
};

export default CustomerWidget;
