import React, { useCallback, useEffect, useState, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Autocomplete,
  Button,
  debounce,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { Button as BsButton, Col, Row } from "react-bootstrap";

import axios from "axios";

import Heading from "../../components/sections/Heading/Heading";
import { formStyle } from "../../theming/formStyle";
import Table from "../../components/atoms/Table/Table";
import Paginator from "../../components/atoms/Paginator/Paginator";
import { MitcoCustomerToMitcoWarehouse } from "./Mitcmitw"
import { useDispatch, useSelector } from "react-redux";
import {
  appendOrdersToBol,
  getAppendableOrders,
  getAppendedOrders,
  getBols,
  resetAppendedOrders,
  resetAppendableOrders,
  resetBols,
  setBol,
} from "../../store/slices/bol/bolSlice";
import { getWarehouses } from "../../store/slices/warehouse/warehouseSlice";
import { Link } from "react-router-dom";

import "./Bol.scss"

const bolHeaderAttributes = [
  { id: "select", label: "Select" },
  { id: "id", label: "BoL" },
  { id: "total", label: "Total" },
  { id: "weight", label: "Weight" },
  { id: "cost", label: "Cost" },
  { id: "customer_id", label: "Customer#" },
  { id: "customer", label: "Customer" },
  { id: "freight_line_num", label: "Freight Line" },
  { id: "freight_line_name", label: "Freight Line Name" },
];

const subOrderHeaderAttributes = [
  { id: "select", label: "Select" },
  { id: "id", label: "Customer Order #" },
  { id: "customer_id", label: "Customer ID" },
  { id: "total_weight", label: "Total Weight" },
  { id: "line_items", label: "Line Items" },
];

const Bol = () => {
  const dispatch = useDispatch();
  const { control, getValues } = useForm();

  const { warehouses } = useSelector((state) => state.warehouse);
  const {
    bol,
    bols,
    appendedOrders,
    appendableOrders,
    total: bolTotal,
    loading: isLoadingBols,
    appendedLoading,
    appendableLoading,
  } = useSelector((state) => state.bol);

  const [bolData, setBolData] = useState([]);
  const [appendedSubOrderData, setAppendedSubOrderData] = useState([]);
  const [appendableSubOrderData, setAppendableSubOrderData] = useState([]);
  const [selectedBol, setSelectedBol] = useState(0);
  const [bolPage, setBolPage] = useState(0);
  const [appendableOrderPage, setAppendableOrderPage] = useState(0);
  const [warehousesList, setWarehousesList] = useState([]);
  const [selectedAppendedOrderIds, setSelectedAppendedOrderIds] = useState([]);
  const [selectedAppendableOrderIds, setSelectedAppendableOrderIds] = useState(
    [],
  );

  useEffect(() => {
    dispatch(getWarehouses());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBols({ warehouse_id: 0 }));
  }, [dispatch]);

  useEffect(() => {
    setWarehousesList(
      warehouses.map((warehouse) => ({
        id: warehouse.warehouse_id,
        label: warehouse.name,
      })),
    );
  }, [warehouses]);

  useEffect(() => {
    setBolData(
      bols?.map((bol) => ({
        id: bol.bill_of_lading,
        view_suborder: (
          <Link to={`/sub-order/${bol.bill_of_lading}`}>
            {bol.bill_of_lading}
          </Link>
        ),
        total: bol.total_sum,
        weight: bol.weight_sum,
        cost: bol.cost_sum,
        customer_id: (
          <Link to={`/customers/${bol.customer_id}`}>{bol.customer_id}</Link>
        ),
        customer: bol.customer_name,
        freight_line_num: bol.freight_line_num,
        freight_line_name: bol.freight_line_name,
      })),
    );
  }, [bols]);

  useEffect(() => {
    setAppendedSubOrderData(
      appendedOrders?.map((v) => ({
        id: v.customer_order_num,
        view_suborder: (
          <Link to={`/sub-order/${v.customer_order_num}`}>
            {v.customer_order_num}
          </Link>
        ),
        customer_id: v.customer_id,
        total_weight: v.freight.weight,
        line_items: v.line_items.length,
      })),
    );
  }, [appendedOrders]);

  useEffect(() => {
    setAppendableSubOrderData(
      appendableOrders?.map((v) => ({
        id: v.customer_order_num,
        view_suborder: (
          <Link to={`/sub-order/${v.customer_order_num}`}>
            {v.customer_order_num}
          </Link>
        ),
        customer_id: v.customer_id,
        total_weight: v.freight.weight,
        line_items: v.line_items.length,
      })),
    );
  }, [appendableOrders]);

  const handleSelectBol = useCallback(
    (id) => {
      setSelectedBol(id);
      setSelectedAppendableOrderIds([]);
      setSelectedAppendedOrderIds([]);

      if (id > 0) {
        const bol = bols.filter((bol) => bol.bill_of_lading === id)[0];
        dispatch(setBol(bol));

        dispatch(getAppendedOrders({ suborders: bol.suborders }));
        dispatch(
          getAppendableOrders({
            customerNum: bol.customer_id,
            warehouseId: bol.warehouse_id,
          }),
        );
        setAppendableOrderPage(0);
      } else {
        dispatch(resetAppendedOrders());
        dispatch(resetAppendableOrders());
      }
    },
    [bols, dispatch],
  );

  const handleFilterChange = debounce(() => {
    const { bol_num, customer_num, customer_name, warehouse } = getValues();

    dispatch(
      getBols({
        bill_of_lading: bol_num,
        warehouse_id: warehouse?.id || 0,
        customer_num: customer_num,
        customer_name: customer_name,
      }),
    );

    setBolPage(0);
  }, 500);

  const handleBolPageChange = (selectedPage) => {
    const { bol_num, customer_num, customer_name, warehouse } = getValues();
    dispatch(
      getBols({
        bill_of_lading: bol_num,
        warehouse_id: warehouse?.id || 0,
        customer_num: customer_num,
        customer_name: customer_name,
        page: selectedPage + 1,
      }),
    );

    setBolPage(selectedPage);
  };

  const handleAppendableOrdersPageChange = (selectedPage) => {
    dispatch(
      getAppendableOrders({
        customerNum: bol.customer_id,
        warehouseId: bol.warehouse_id,
        page: selectedPage + 1,
      }),
    );

    setAppendableOrderPage(selectedPage);
  };

  const handleAppend = (isAll) => {
    if (isAll) {
      setAppendedSubOrderData([
        ...appendedSubOrderData,
        ...appendableSubOrderData,
      ]);
      setAppendableSubOrderData([]);
      setSelectedAppendedOrderIds([]);
      setSelectedAppendableOrderIds([]);
    } else {
      setAppendedSubOrderData([
        ...appendedSubOrderData,
        ...appendableSubOrderData.filter((item) =>
          selectedAppendableOrderIds.includes(item.id),
        ),
      ]);
      setAppendableSubOrderData(
        appendableSubOrderData.filter(
          (item) => !selectedAppendableOrderIds.includes(item.id),
        ),
      );
      setSelectedAppendedOrderIds([]);
      setSelectedAppendableOrderIds([]);
    }
  };

  const handleReset = (isAll) => {
    if (isAll) {
      setAppendedSubOrderData([]);
      setAppendableSubOrderData([
        ...appendableSubOrderData,
        ...appendedSubOrderData,
      ]);
      setSelectedAppendedOrderIds([]);
      setSelectedAppendableOrderIds([]);
    } else {
      setAppendedSubOrderData(
        appendedSubOrderData.filter(
          (item) => !selectedAppendedOrderIds.includes(item.id),
        ),
      );
      setAppendableSubOrderData([
        ...appendableSubOrderData,
        ...appendedSubOrderData.filter((item) =>
          selectedAppendedOrderIds.includes(item.id),
        ),
      ]);
      setSelectedAppendedOrderIds([]);
      setSelectedAppendableOrderIds([]);
    }
  };


  const handleApply = async () => {
    const oldAppendedIds = appendedOrders.map(
      (item) => item.customer_order_num,
    );
    const newAppendedIds = appendedSubOrderData.map((item) => item.id);

    const appendIds = newAppendedIds.filter(
      (id) => !oldAppendedIds.includes(id),
    );
    const resetIds = oldAppendedIds.filter(
      (id) => !newAppendedIds.includes(id),
    );

    const actionPromises = [];

    if (appendIds.length > 0) {
      actionPromises.push(
        dispatch(
          appendOrdersToBol({
            customerOrderNum: Number(bol.bill_of_lading),
            subOrderIds: appendIds,
          }),
        ),
      );
    }

    if (resetIds.length > 0) {
      actionPromises.push(
        dispatch(
          resetBols({
            customerOrderNum: Number(bol.bill_of_lading),
            subOrderIds: resetIds,
          }),
        ),
      );
    }

    await Promise.all(actionPromises);

    const { bol_num, customer_num, customer_name, warehouse } = getValues();

    dispatch(
      getBols({
        bill_of_lading: bol_num,
        warehouse_id: warehouse?.id || 0,
        customer_num: customer_num,
        customer_name: customer_name,
        page: bolPage + 1,
      }),
    );
  };

  useEffect(() => {
    if (selectedBol && bols.length > 0) {
      const bol = bols.find((bol) => bol.bill_of_lading === selectedBol);
      if (bol) {
        dispatch(getAppendedOrders({ suborders: bol.suborders }));
        dispatch(
          getAppendableOrders({
            customerNum: bol.customer_id,
            warehouseId: bol.warehouse_id,
          }),
        );
      }
    }
  }, [selectedBol, bols, dispatch]);

  const handleAppendableSelectionChange = (selectedIds) => {
    setSelectedAppendableOrderIds(selectedIds);
  };

  const handleAppendedSelectionChange = (selectedIds) => {
    setSelectedAppendedOrderIds(selectedIds);
  };

  return (
    <div className="m-4">
      <Heading title="Bill of Lading" />

      <Row className="bolHead">
        <Col>
          <Controller
            name="bol_num"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Bill of Lading"
                name="bol_num"
                type="search"
                variant="outlined"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleFilterChange();
                }}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="customer_num"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Customer ID"
                name="customer_num"
                type="search"
                variant="outlined"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleFilterChange();
                }}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="customer_name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <TextField
                label="Customer Name"
                name="customer_name"
                type="search"
                variant="outlined"
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  handleFilterChange();
                }}
                style={formStyle.inputStyle}
                InputLabelProps={formStyle.inputLabelProps}
              />
            )}
          />
        </Col>
        <Col>
          <Controller
            name="warehouse"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                name="warehouse"
                className="autocomplete-input"
                value={value}
                defaultValue={{ id: 0, label: "TN" }}
                onChange={(e, value) => {
                  onChange(value);
                  handleFilterChange();
                }}
                options={warehousesList}
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Warehouse"
                    style={formStyle.inputStyle}
                    InputLabelProps={formStyle.inputLabelProps}
                  />
                )}
              />
            )}
          />
        </Col>

      </Row>

      <Row item xs={12} className="mt-4">
        <Table
          className="bols-table"
          selector="radio"
          headerAttributes={bolHeaderAttributes}
          dataList={bolData}
          onSelectRow={handleSelectBol}
          selectedItem={selectedBol}
          hideId={true}
          isLoading={isLoadingBols}
        />
        <Paginator
          rowsPerPage={50}
          totalOrders={bolTotal}
          page={bolPage}
          setPage={(p) => handleBolPageChange(p)}
        />

        <Row className="append">
          <Col className="left">
            <Typography variant="body1">Appended Orders</Typography>
            <Table
              className="suborders-table"
              selector="checkbox"
              headerAttributes={subOrderHeaderAttributes}
              dataList={appendedSubOrderData}
              selectedItems={selectedAppendedOrderIds}
              onSelectionChange={handleAppendedSelectionChange}
              hideId={true}
              isLoading={appendedLoading}

            />
            <Paginator
              rowsPerPage={50}
              totalOrders={appendableSubOrderData.length}
              page={appendableOrderPage}
              setPage={(p) => handleAppendableOrdersPageChange(p)}
            />
          </Col>
          <Col className="buttons">
            <Row>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleReset(true)}
                disabled={appendedSubOrderData.length === 0}
                aria-label="move all selected right"
              >
                ≫
              </Button>
            </Row><Row>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleReset(false)}
                disabled={selectedAppendedOrderIds.length === 0}
                aria-label="move selected right"
              >
                &gt;
              </Button>
            </Row>
            <Row>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleAppend(false)}
                disabled={selectedAppendableOrderIds.length === 0}
                aria-label="move selected left"
              >
                &lt;
              </Button>
            </Row>
            <Row>
              <Button
                sx={{ my: 0.5 }}
                variant="outlined"
                size="small"
                onClick={() => handleAppend(true)}
                disabled={appendableSubOrderData.length === 0}
                aria-label="move all left"
              >
                ≪
              </Button>
            </Row>
          </Col>
          <Col className="right">
            <Typography variant="body1">Appendable Orders</Typography>
            <Table
              className="suborders-table"
              selector="checkbox"
              headerAttributes={subOrderHeaderAttributes}
              dataList={appendableSubOrderData}
              selectedItems={selectedAppendableOrderIds}
              onSelectionChange={handleAppendableSelectionChange}
              hideId={true}
              isLoading={appendableLoading}
            />
            <Paginator
              rowsPerPage={50}
              totalOrders={appendableSubOrderData.length}
              page={appendableOrderPage}
              setPage={(p) => handleAppendableOrdersPageChange(p)}
            />
          </Col>
        </Row>

        <div className="text-end" style={{ marginTop: "-36px" }}>
          <BsButton disabled={selectedBol === 0} onClick={handleApply}>
            Apply
          </BsButton>
        </div>
      </Row>
    </div>
  );
};

export default Bol;
