import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiPost } from "../../api/apiPost";
import { apiPut } from "../../api/apiPut";
import { apiDelete } from "../../api/apiDelete";
import { apiGet } from "../../api/apiGet";
import { SubOrderModel } from "../../api/models/SubOrderModel";
import formatItems from "../../helpers/formatItems";
import { formatErrorMessage } from "../../helpers/helperFunctions";
import { calculateCcCharge, setCustomerId } from "./customer/customerSlice";
import { setCleaningAll } from "./headerSlice";
import {
  COMPLETED_ORDER_STATUS,
  INVOICED_ORDER_STATUS,
  EDIT_ORDER_HOLD_STATUS,
  OPEN_ORDER_STATUS,
  PENDING_ORDER_STATUS,
  POSTED_ORDER_STATUS,
  ORDER_HOLD_STATUS,
  PRICE_ORDER_HOLD_STATUS,
  APPROVED_ORDER_STATUS,
  IN_PROGRESS_ORDER_STATUS,
} from "../../helpers/const";
import { whChangeEvent } from "./item/itemSlice";

/**
 * Function to create a new subOrder
 * @param {object} newSubOrder
 */
export const createSubOrder = createAsyncThunk(
  "subOrder/Save",
  async ({ comment, date, conf }, thunkAPI) => {

    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrder, subOrderId },
        items: { items },
        freight,
        customer: { customerId },
      } = storeStates;

      let newStatus = subOrder.status;
  
      let createdSubOrder = await apiPost.subOrder({
        ...subOrder,
        ...newStatus,
        freight: { ...subOrder.freight, ...freight },

      }, conf);

      if (items.length > 0) {
        const itemsToCreate = items
          .filter((i) => i.id === 0)
          .map((item) => {
            return apiPost.lineItem(item, createdSubOrder.customer_order_num);
          });
        await Promise.all(itemsToCreate.map((f) => f())).then();
      }

      if (comment) {
        const validatedDate = date === "Invalid Date" ? "today" : date;
        await apiPost.createSalesContactComment(
          customerId,
          comment,
          validatedDate,
        );
      }

      let creditCheck = await apiGet.creditCheck(createdSubOrder.customer_order_num)
      let marginError = await apiGet.marginError(createdSubOrder.customer_order_num)
      
      

      createdSubOrder.hold_status = creditCheck.status
      createdSubOrder.status = creditCheck.status

      

      if (marginError.answer === "invalid") {
        createdSubOrder.status = PENDING_ORDER_STATUS
        createdSubOrder.hold_status = PRICE_ORDER_HOLD_STATUS
        return {
        id: createdSubOrder.customer_order_num,
        customer: createdSubOrder.customer_id,
        customMsg: 2
        }
      }

      return {
        id: createdSubOrder.customer_order_num,
        customer: createdSubOrder.customer_id,
        customMsg: 1,
        status: createdSubOrder.status = PENDING_ORDER_STATUS,
        hold: createdSubOrder.hold_status = PRICE_ORDER_HOLD_STATUS,
      };
    } catch (error) {
        if (error?.response?.data) {
          return thunkAPI.rejectWithValue({
            axiosError: error,
            customMsg: "Error saving the order. " + formatErrorMessage(error?.response?.data)
          });
        }
        return thunkAPI.rejectWithValue({
          axiosError: error,
          customMsg: "Error saving the order.",
        });

    }
  }
);

export const printBol = createAsyncThunk(
  "subOrder/printBol",
  async (_, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrder, subOrderId },
      } = storeStates;


      if (subOrder.status === 1) apiPost.printBol(subOrderId)
      return {status: subOrder.status}
    } catch (err) {
      if (err?.response?.data) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: (
            <div>
              <p>Error updating the order.</p>
              {formatErrorMessage(err?.response?.data)}
            </div>
          ),
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error updating the order.",
      });
    }
  }
)

/**
 * Function to update a subOrder
 * @param {object} subOrder
 * @param {array} itemsToDelete
 * @param {number} subOrderId
 *
 */
export const updateSubOrder = createAsyncThunk(
  "subOrder/Update",
  async (_parameters, thunkAPI) => {
    try {
      const storeStates = thunkAPI.getState();
      const {
        subOrder: { subOrderId, subOrder },
        items: { items },
        freight,
        eventHandler: {
          autoSaveOrder: { itemsChanged },
        },
      } = storeStates;
      var newStatus = subOrder.status
      
      await apiPut.subOrder({
        ...subOrder,
        ...newStatus,
        freight: { ...subOrder.freight, ...freight },
      });

      if (itemsChanged) {
        const sortedAllItems = [...items].sort((a, b) => a.ln - b.ln);

        if (sortedAllItems.length > 0) {
          const itemsToUpdate = sortedAllItems.filter((i) => i.id > 0);
          for (const itemToUpdate of itemsToUpdate) {
            await apiPut.lineItem(itemToUpdate, subOrderId);
          }

          const itemsToCreate = sortedAllItems
            .filter((i) => i.id === 0)
            .map((parentItem) => {
              return apiPost.lineItem(parentItem, subOrderId);
            });

          await Promise.all(itemsToCreate.map((f) => f())).then();
        }
      }

      const updatedSubOrder = await apiGet.subOrderById(subOrderId);
      const formattedItems = formatItems(
        updatedSubOrder.line_items,
        updatedSubOrder.status,
        "subOrder",
      );
      

      let creditCheck = await apiGet.creditCheck(updatedSubOrder.customer_order_num)
      let marginError = await apiGet.marginError(updatedSubOrder.customer_order_num)

      updatedSubOrder.hold_status = creditCheck.hold_status
      updatedSubOrder.status = creditCheck.status

      const freightLineId =
        updatedSubOrder.freight?.freight_line?.freight_line_num;
      let freightLine = null;

      if (freightLineId) {
        freightLine = {
          id: freightLineId,
          title: `${freightLineId} - ${updatedSubOrder.freight?.freight_line.name}`,
        };
      }

      if (marginError.answer === "invalid") {
      updatedSubOrder.orderStatus = PENDING_ORDER_STATUS
      
        return {
          id: updatedSubOrder.customer_order_num,
          updatedSubOrder: {
            ...updatedSubOrder,
            freight: {
              ...updatedSubOrder.freight,
              freight_line: freightLine,
            },
          },
          lineItems: formattedItems,
        customMsg: 2
        }
      }

      return {
        updatedSubOrder: {
          ...updatedSubOrder,
          freight: {
            ...updatedSubOrder.freight,
            freight_line: freightLine,
          },
        },
        lineItems: formattedItems,
      };
    } catch (err) {
      if (err?.response?.data) {
        return thunkAPI.rejectWithValue({
          axiosError: err,
          customMsg: (
            <div>
              <p>Error updating the order.</p>
              {formatErrorMessage(err?.response?.data)}
            </div>
          ),
        });
      }
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error updating the order.",
      });
    }
  },
);

/**
 * Function to consult a suborder data
 * @param {number} id - id of the suborder
 */
export const getSubOrder = createAsyncThunk(
  "subOrder/Get",
  async (id, thunkAPI) => {
    try {
      const subOrderData = await apiGet.subOrderById(id);
      const formattedItems = formatItems(
        subOrderData.line_items,
        subOrderData.status,
        "subOrder",
      );

      const drop_ship_address_id =
        subOrderData.freight?.drop_ship_address?.drop_ship_address_id;
      const freightLineId =
        subOrderData.freight?.freight_line?.freight_line_num;
      let freightLine = null;

      if (freightLineId) {
        freightLine = {
          id: freightLineId,
          title: `${subOrderData.freight.freight_line.name} - ${freightLineId}`,
        };
      }

      return {
        subOrderData: {
          ...subOrderData,
          freight: {
            ...subOrderData.freight,
            freight_line: freightLine,
            drop_ship_address_id: drop_ship_address_id
              ? drop_ship_address_id
              : null,
          },
        },
        formattedItems,
      };
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the order.",
      });
    }
  },
);

/**
 * Function to delete a subOrder
 * @param {number} id -  id of the suborder
 */
export const deleteSubOrder = createAsyncThunk(
  "subOrder/Delete",
  async (id, thunkAPI) => {
    try {
      await apiDelete.deleteSubOrder(id);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error deleting the order.",
      });
    }
  },
);

/**
 * Function to convert a quote to a suborder
 * @param {number} id -  id of the quote
 */
export const saveAsOrder = createAsyncThunk(
  "subOrder/convertQuoteToSubOrder",
  async ({ id }, thunkAPI) => {
    try {
      
    const subOrder = await apiPost.saveAsOrder(id);
      
    let marginError = await apiGet.marginError(subOrder)

    console.log(marginError.answer)
    if (marginError.answer === "invalid") {
      return {
      suborder: subOrder,
      customMsg: 2
      }
    }
      return {suborder: subOrder};
    } catch (error) {
        if (error?.response?.data) {
          return thunkAPI.rejectWithValue({
            axiosError: error,
            customMsg: (
              <div>
                <p>Error saving the order.</p>
                {formatErrorMessage(error?.response?.data)}
              </div>
            ),
          });
        }
        return thunkAPI.rejectWithValue({
          axiosError: error,
          customMsg: "Error saving the order.",
        });
      
    }
  },
);

/**
 * Function to get all subOrders of a customer and company
 *  @param {object} thunkAPI - Redux object
 */
export const getAllSubOrders = createAsyncThunk(
  "subOrder/all",
  async (_parameters, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      customer: { customerId },
    } = storeStates;

    try {
      const list = await apiGet.ordersList({
        page: 1,
        customerId,
        orderBy: "suborder_num",
        sortOrder: "dsc",
      });
      return list.results.map((subOrder) => subOrder.customer_order_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the list of orders.",
      });
    }
  },
);

export const getSubOrders = createAsyncThunk(
  "subOder/getList",
  async (params, thunkAPI) => {
    try {
      const suborders = await apiGet.ordersList({
        ...params,
        sortOrder: "dsc",
      });

      return suborders;
    } catch (error) {
      return thunkAPI.rejectWithValue({
        axiosError: error,
        customMsg: "Error getting the sub-orders.",
      });
    }
  },
);

/**
 * Function to search subOrders by ID
 * @param {number} subOrderId
 * @param {object} thunkAPI - Redux object
 */
export const searchSubOrdersbyId = createAsyncThunk(
  "subOrder/searchByID",
  async (subOrderId, thunkAPI) => {
    const storeStates = thunkAPI.getState();
    const {
      customer: { customerId },
    } = storeStates;
    try {
      const list = await apiGet.ordersList({
        customerOrderNum: subOrderId,
        page: 1,
        customerId,
        orderBy: "suborder_num",
        sortOrder: "dsc",
      });
      return list.results.map((subOrder) => subOrder.customer_order_num);
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the list of orders.",
      });
    }
  },
);

export const getOrderListByParameters = createAsyncThunk(
  "subOrder/getOrdersByParemeters",
  async (params, thunkAPI) => {
    try {
      const resp = await apiGet.ordersList(params);

      return resp;
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error getting the list of orders.",
      });
    }
  },
);

export const checkDuplicatedPO = createAsyncThunk(
  "subOrder/checkDuplicatedPO",
  async ({ subOrderId, customerNum, customerPo }, thunkAPI) => {
    try {
      const response = await apiGet.ordersList({
        customerNum,
        customerPo,
        orderStatus: [OPEN_ORDER_STATUS.id, PENDING_ORDER_STATUS.id],
        strictMatch: true,
      });

      return response.results.filter(
        (item) => item.customer_order_num !== subOrderId,
      );
    } catch (err) {
      return thunkAPI.rejectWithValue({
        axiosError: err,
        customMsg: "Error checking CustomerPO duplications.",
      });
    }
  },
);

export const subOrderSlice = createSlice({
  name: "subOrderSlice",
  initialState: {
    total: 0,
    subOrderId: 0,
    subOrder: SubOrderModel,
    allSubOrders: [],
    loadingAllSubOrders: false,
    subOrdersLocked: false,
    checkDuplicatedDisabled: true,
  },
  reducers: {
    setShippingRules: (state, action) => {
      return {
        ...state,
        subOrder: {
            ...state.subOrder,
            freight: {
              ...state.subOrder.freight,
              ignore_shipping_rules: action.payload
             }
        }
        
      }
    },
    resetSubOrderId: (state) => {
      return {
        ...state,
        subOrderId: 0,
        subOrdersLocked: false,
      };
    },
    setSubOrder: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          ...action.payload,
        },
      };
    },
    setWarehouseData: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          ...action.payload,
        },
      };
    },
    setOrderDetails: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          ...action.payload,
          isUpdated: true,
        },
      };
    },
    setHoldDate: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          hold_date: action.payload,
        },
      };
    },
    setOrderStatus: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          status: action.payload,
        },
      };
    },
    setOrderHoldStatus: (state, action) => {
      return {
        ...state,
        subOrder: {
          ...state.subOrder,
          hold_status: action.payload,
        },
      };
    },
    setLoadingAllSubOrders: (state, action) => {
      return {
        ...state,
        loadingAllSubOrders: action.payload,
      };
    },
    setCheckDuplicatedDisabled: (state, action) => {
      return {
        ...state,
        checkDuplicatedDisabled: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveAsOrder.fulfilled, (state, action) => {
        state.subOrderId = action.payload;
      })

      .addCase(createSubOrder.fulfilled, (state, action) => {
        state.subOrderId = action.payload.id;
      })

      .addCase(updateSubOrder.fulfilled, (state, action) => {
        state.subOrderId = action.payload.updatedSubOrder.customer_order_num;
        state.subOrder = action.payload.updatedSubOrder;
      })

      .addCase(getSubOrder.fulfilled, (state, action) => {
        state.subOrder = action.payload.subOrderData;
        state.subOrderId = action.payload.subOrderData.customer_order_num;
        state.subOrdersLocked = [
          COMPLETED_ORDER_STATUS.id,
          INVOICED_ORDER_STATUS.id,
          POSTED_ORDER_STATUS.id,
        ].includes(action.payload.subOrderData.status);
      })
      .addCase(getSubOrders.pending, (state, action) => {
        state.loadingAllSubOrders = true;
      })
      .addCase(getSubOrders.fulfilled, (state, action) => {
        state.allSubOrders = action.payload.results;
        state.loadingAllSubOrders = false;
        state.total = action.payload.count;
      })
      .addCase(setCustomerId, (state, action) => {
        state.subOrder.customer_id = action.payload;
      })
      .addCase(setCleaningAll, (state) => {
        const { took_by, ...rest } = SubOrderModel;
        state.subOrder = {
          ...state.subOrder,
          ...rest,
        };
      })
      .addCase(getAllSubOrders.fulfilled, (state, action) => {
        state.allSubOrders = action.payload;
      })
      .addCase(searchSubOrdersbyId.fulfilled, (state, action) => {
        state.allSubOrders = action.payload;
        state.loadingAllSubOrders = false;
      })
      .addCase(whChangeEvent.fulfilled, (state, action) => {
        return {
          ...state,
          subOrder: {
            ...state.subOrder,
            ...action.payload.warehouse,
          },
        };
      })
      .addCase(calculateCcCharge.fulfilled, (state, action) => {
        return {
          ...state,
          subOrder: {
            ...state.subOrder,
            creditcard_charge: action.payload.creditcardCharge,
            terms: action.payload.terms,
          },
        };
      })
      .addCase(checkDuplicatedPO.fulfilled, (state, action) => {
        return {
          ...state,
          subOrder: {
            ...state.subOrder,
            poDuplicatedSubOrders: action.payload,
          },
        };
      });
  },
});

export const {
  resetSubOrderId,
  setShippingRules,
  setSubOrder,
  setWarehouseData,
  setMainItems,
  setOrderDetails,
  setHoldDate,
  setOrderStatus,
  setOrderHoldStatus,
  setLoadingAllSubOrders,
  setCheckDuplicatedDisabled,
} = subOrderSlice.actions;

export default subOrderSlice.reducer;
